Game
<template>
  <div id="game" v-loading="loading">
    <div class="cm-content">
      <h-game @add="handleAdd"></h-game>
      <br />
      <el-table :data="data">
        <el-table-column label="图标" width="120">
          <template slot-scope="scope">
            <img
              v-if="scope.row.img_url"
              :src="scope.row.img_url"
              class="avatar"
            />
            <div v-else>--</div>
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="游戏地址" prop="entry_url"></el-table-column>
        <el-table-column label="添加时间">
          <template slot-scope="scope">
            {{ scope.row.created_at | formatDateTime }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.pk)">
              编辑
            </el-button>
            <el-button
              v-if="scope.row.state === -1"
              size="mini"
              type="primary"
              @click="handleChangeState(scope.row.pk, { state: 1 })"
            >
              上架
            </el-button>
            <el-button
              v-if="scope.row.state === 1"
              size="mini"
              type="warning"
              @click="handleChangeState(scope.row.pk, { state: -1 })"
            >
              下架
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row type="flex" justify="center">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="10"
          :total="total"
        >
        </el-pagination>
      </el-row>
      <d-game ref="dialog" @change="handleDialogChange"></d-game>
    </div>
  </div>
</template>

<script>
import HGame from "@/components/headers/HGame";
import DGame from "@/components/dialogs/DGame";

import game from "@/api/game";

export default {
  name: "game",
  data() {
    return {
      loading: false,
      data: [],
      params: {},
      total: 0,
      currentPage: 1,
    };
  },
  created() {
    this.handleInit(this.$route);
    this.handleList(this.params);
  },
  components: {
    HGame,
    DGame,
  },
  methods: {
    handleInit(route) {
      if (route.query.page) {
        this.params["page"] = route.query.page;
      }
      if (route.query.keyword) {
        this.params["keyword"] = route.query.keyword;
      }
    },
    handleList(params) {
      game.list(params).then((res) => {
        this.data = res.results;
        this.total = res.count;
        this.loading = false;
        this.currentPage = parseInt(this.$route.query.page);
      });
    },
    handleAdd() {
      this.$refs.dialog.handleAdd();
    },
    handleEdit(pk) {
      this.$refs.dialog.handleEdit(pk);
    },
    handleChangeState(pk, data) {
      this.$confirm("是否处理？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          game
            .changeState(pk, data)
            .then(() => {
              this.handleList(this.params);
              this.$message({
                type: "success",
                message: "操作成功！",
              });
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {});
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.params["page"] = val;
      let params = Object.assign({}, this.params);
      this.$router
        .push({
          name: "game",
          query: params,
        })
        .catch(() => {});
    },
    handleDialogChange(val) {
      if (val) {
        this.handleList(this.params);
      } else {
        this.handleList();
      }
    },
  },
  watch: {
    $route: {
      deep: true,
      handler: function (val) {
        if (val.name === "game") {
          this.handleInit(val);
          this.handleList(this.params);
        }
      },
    },
  },
};
</script>

<style lang="stylus" scope>
#game {
  display: flex;
  height: 100%;

  .avatar {
    width: 80px;
    height: 80px;
  }
}
</style>
