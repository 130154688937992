<template>
  <div id="h-game">
    <el-row>
      <el-button class="cm-float-right" size="small" @click="handleAdd">
        新增
      </el-button>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "h-game",
  methods: {
    handleAdd() {
      this.$emit("add");
    },
  },
};
</script>
