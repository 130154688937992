<template>
  <div id="d-game">
    <el-dialog
      :visible.sync="visible"
      :title="title"
      @closed="handleClosed"
      width="900px"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        label-position="left"
      >
        <el-form-item label="图标" prop="img_url" required>
          <el-upload
            class="upload-demo"
            :limit="1"
            :headers="setHeaders()"
            :action="setURL()"
            :on-success="handleUploadSuccess"
            :on-error="handleUploadError"
            :file-list="fileList"
            list-type="picture"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="名称" prop="name" required>
          <el-input
            size="small"
            type="text"
            v-model="form.name"
            placeholder="名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="链接" prop="entry_url" required>
          <el-input
            size="small"
            type="text"
            v-model="form.entry_url"
            placeholder="链接"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="handleOk('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import game from "@/api/game";

export default {
  name: "d-game",
  data() {
    return {
      visible: false,
      form: {
        name: "",
        img_url: "",
        entry_url: "",
        type: 1,
      },
      fileList: [],
      title: "添加游戏",
      action: "create",
    };
  },
  methods: {
    handleAdd() {
      this.visible = true;
      this.action = "create";
      this.title = "添加游戏";
    },
    handleEdit(id) {
      this.action = "edit";
      this.title = "编辑游戏";
      game.get(id).then((res) => {
        this.visible = true;
        this.form = res.results;
      });
    },
    handleOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.action === "create") {
            let data = this.form;
            game
              .create(data)
              .then(() => {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "添加成功！",
                });
                this.$emit("change", false);
              })
              .catch((err) => {
                this.$message({
                  type: "warning",
                  message: err.message,
                });
              });
          } else {
            let id = this.form.id;
            let data = this.form;
            game
              .update(id, data)
              .then(() => {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "编辑成功！",
                });
                this.$emit("change", true);
              })
              .catch((err) => {
                this.$message({
                  type: "warning",
                  message: err.message,
                });
              });
          }
        }
      });
    },
    handleUploadSuccess(response) {
      this.form.img_url = response.results.url;
    },
    handleUploadError() {
      this.$message({
        type: "warning",
        message: "上传失败！",
      });
    },
    setURL() {
      return `${process.env.VUE_APP_API_ROOT}/upload`;
    },
    setHeaders() {
      return {
        Authorization: `Bearer ${localStorage.getItem("auth.token")}`,
      };
    },
    handleCancel() {
      this.visible = false;
    },
    handleClosed() {
      this.form = {
        name: "",
        img_url: "",
        entry_url: "",
        type: 1,
      };
      this.$refs.form.resetFields();
    },
  },
};
</script>
