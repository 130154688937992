import api from "@/api";

const game = {
  list(params) {
    return api.get(`/game`, {
      params: params,
    });
  },
  create(data) {
    return api.post(`/game`, data);
  },
  get(pk) {
    return api.get(`/game/${pk}`);
  },
  update(pk, data) {
    return api.patch(`/game/${pk}`, data);
  },
  changeState(pk, data) {
    return api.patch(`/game/${pk}/state`, data);
  },
  destroy(pk) {
    return api.delete(`/game/${pk}`);
  },
};

export default game;
